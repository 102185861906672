<!--
程序名：问卷设计主页面
功能：对问卷进行设计
-->
<template>
  <div class="home">
    <el-row>
      <el-col :span="6">
        <!--操作栏-->
        <div class="opera">
          <el-tooltip class="item" effect="dark" :content="ifDisabledAddPaperButton()?'该活动已有相关问卷':'创建问卷'"
            placement="bottom">
            <div style="display: inline-block;margin-right: 10px;">
              <el-button name="plus" icon="el-icon-plus" size="large" type="text" class="rightButton"
                @click="addPaperButtonClick" :disabled="ifDisabledAddPaperButton()"></el-button>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="编辑问卷" placement="bottom">
            <el-button name="edit" icon="el-icon-edit" type="text" class="rightButton" @click="editPaper"
              :disabled="nowSelect.id === '0' || nowSelect.id === null"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="nowSelect.status === '0' ? '暂停问卷' : '发布问卷'"
            placement="bottom">
            <el-button name="change_status"
              :icon="nowSelect.status === '0'? ' el-icon-video-pause': 'el-icon-video-play'" type="text"
              class="rightButton" @click="changPaperStatus" :disabled="nowSelect.id === '0' || nowSelect.id === null">
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="预览问卷" placement="bottom">
            <el-button name="preview" icon="el-icon-view" type="text" class="rightButton" @click="previewPaper"
              :disabled="nowSelect.id === '0' || nowSelect.id === null"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除问卷" placement="bottom">
            <el-button name="delete" icon="el-icon-delete" type="text" class="rightButton" @click="deletePaper"
              :disabled="nowSelect.id === '0' || nowSelect.id === null"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="分享问卷" placement="bottom">
            <el-button name="share" icon="el-icon-share" type="text" class="rightButton" @click="sharePaper"
              :disabled="nowSelect.id === '0' || nowSelect.id === null"></el-button>
          </el-tooltip>
        </div>

        <!--左侧导航栏-->
        <el-menu :default-active="(defaultActive + 1).toString()" v-loading="loading" class="menu">
          <!-- 问卷列表 -->
          <div
            style="width: 100%; text-align: center; font-size: 15px; line-height: 20px; margin-top: 20px; color: #303133;"
            v-if="paperList.length === 0">
            点击上方&nbsp;+&nbsp;创建第一个问卷
          </div>
          <el-menu-item v-for="(item, index) in paperList" :key="index" :index="(index + 1).toString()"
            @click="paperClick(item.id, index)">
            <el-tooltip class="item" effect="dark" :content="item.paperTitle" placement="right">
              <div>
                <span slot="title" style="display: inline-block" class="title">
                  <el-tag type="danger" size="mini" style="color: #f5222d" v-if="item.paperState === '1'"
                    effect="plain">
                    未发布
                  </el-tag>
                  <el-tag size="mini" v-if="item.paperState === '0'" effect="plain" type="success">已发布</el-tag>
                  <el-tag size="mini" v-if="item.paperState === '2'" effect="plain" type="info">已过期</el-tag>
                  {{ item.paperTitle }}
                </span>
              </div>
            </el-tooltip>
          </el-menu-item>
        </el-menu>
      </el-col>

      <el-col :span="18" v-loading="loading">
        <!--标签页-->
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="问卷设计" name="paperDesign">
            <!--内容区域-->
            <div class="content">
              <!-- 本问卷系统支持九宫格抽奖 -->
              <div style="display: flex; justify-content: flex-end;" v-if="nowSelect.id !== null">
                <el-button name="award" v-if="nowSelect.type !== '0'" type="primary" :disabled="lotteryState === '1'" @click="openSetLotteryDialog()"
                  style="float: right">设置抽奖环节</el-button>
                <el-button :type="lotteryState !== '1' ? 'primary' : 'danger'" v-if="nowSelect.type !== '0'"
                  @click="lotteryStop()">
                  {{lotteryState !== "1" ? "开始抽奖活动" : "暂停抽奖活动"}}
                </el-button>
              </div>
              <br /><br />
              <div v-show="nowSelect.id === '0' || nowSelect.id === null">
                请先选择问卷
              </div>
              <design ref="design" v-show="nowSelect.id !== 0 && nowSelect.id !== null"></design>
            </div>
          </el-tab-pane>
          <el-tab-pane label="结果统计" name="statistics">
            <!--内容区域-->
            <div class="content" v-loading="loading" element-loading-text="加载中...">
              <div v-show="nowSelect.id === '0' || nowSelect.id === null">
                <div>
                  <br /><br />
                  请先选择问卷
                </div>
              </div>
              <div v-show="nowSelect.id !== 0 && nowSelect.id !== null">
                <el-button type="primary" style="float: left" @click="exportAnswer()">导出Excel</el-button>
                <br /><br /><br /><br />
                <el-card class="box-card">
                  <div class="text item" style="padding: 18px 0;">
                    目前共 {{ people.total }} 人作答（其中内部系统 {{ people.inner }} 人，外部用户 {{ people.outer }} 人）。
                  </div>
                  <div class="text item" style="padding: 18px 0;">
                    回答此问卷的用户来源：{{ company }}。
                  </div>
                  <div v-if="nowSelect.status === '0'" class="text item" style="padding: 18px 0;">
                    本问卷有效期还剩：{{ expirationTime }}
                  </div>
                  <div v-if="nowSelect.expirationTime && nowSelect.status === '0'" class="text item" style="padding: 18px 0;">
                    到期时间：{{new Date(+new Date(nowSelect.expirationTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}
                  </div>
                </el-card>
                <br>
                <el-card v-if="nowSelect.type !== '0'" class="box-card">
                  <div class="text item" style="padding: 18px 0;">
                    抽奖结果统计：
                  </div>
                  <el-table
                    :data="awardResult"
                    stripe
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="awardName"
                      label="奖品名称"
                      min-width="160">
                    </el-table-column>
                    <el-table-column
                      prop="userName"
                      label="中奖人姓名"
                      width="140">
                    </el-table-column>
                    <el-table-column
                      prop="punterName"
                      label="收货人姓名"
                      width="140">
                    </el-table-column>
                    <el-table-column
                      prop="phone"
                      label="收货人联系方式"
                      width="140">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                      label="收货人地址"
                      min-width="160">
                    </el-table-column>
                    <el-table-column
                      prop="postcode"
                      label="收货人地址邮编"
                      width="140">
                    </el-table-column>
                  </el-table>
                </el-card>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <!--添加问卷弹窗-->
    <el-dialog :title="isAddPaper == true ? getTypeTitle() : '修改问卷'" :visible.sync="dialogShow" :close-on-click-modal="false" class="dialog" v-loading="dialogLoading">
      <el-form ref="willAddPaper" :model="willAddPaper" label-width="80px">
        <el-form-item label="问卷标题" style="width: 100%" prop="title" :rules="[{ required: true, message: '问卷标题不能为空' }]">
          <el-input v-model="willAddPaper.title" placeholder="请输入问卷标题" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="问卷描述" prop="desc" style="width: 100%">
          <el-input v-model="willAddPaper.desc" type="textarea" placeholder="请输入问卷描述" maxlength="200" rows="5" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item style="width: 100%; text-align: right">
          <el-button @click="dialogShow = false">取消</el-button>
          <el-button type="primary" style="margin-left: 10px" @click="addPaper('willAddPaper')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--分享问卷弹窗-->
    <el-dialog title="分享问卷" :visible.sync="shareDialogShow" :close-on-click-modal="true" class="dialog"
      @opened="makeQrcode">
      <el-form ref="form" :model="shareInfo" label-width="80px">
        <el-form-item label="问卷链接" style="width: 100%">
          <el-row>
            <el-col :span="16">
              <el-input v-model="shareInfo.url" readonly></el-input>
            </el-col>
            <el-col :span="8">
              <el-button style="margin-left: 5px" v-clipboard:copy="shareInfo.url" v-clipboard:success="copyUrlSuccess"
                v-clipboard:error="copyUrlError">复制</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="二维码" style="width: 100%">
          <canvas id="canvas" style="width: 150px; height: 150px"></canvas>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 抽奖设置弹窗 -->
    <el-dialog title="抽奖设置" :visible.sync="lotteryDialogShow" :close-on-click-modal="false" class="dialog"
      v-loading="dialogLoading" width="60%">
      <div>
        <el-row>
          <el-button @click="addPrize">新增奖品</el-button>
          <el-button type="danger" plain @click="removeCheckboxRow()">删除选中</el-button>
        </el-row>
        <br />

        <el-table
          ref="multipleTable"
          :data="prizeData"
          tooltip-effect="dark"
          style="width: 100%"
          @select="handleSelection">
          <el-table-column
            type="selection"
            :selectable="checkboxInit"
            min-width="55">
          </el-table-column>
          <el-table-column
            prop="name"
            label="奖品名称"
            :render-header="renderHeader"
            min-width="140"
            align="center">
            <template slot="header">
              <span>
                <span style="color: #f5222d">&nbsp;*&nbsp;</span>
                <span>奖品名称</span>
              </span>
            </template>
            <template slot-scope="scope">
              <!-- 已经有用户抽中的奖品不允许再修改名称。 -->
                <el-input size="small" v-model="prizeData[scope.$index]['name']" :maxlength="25" :disabled="sentAwardIds.indexOf(prizeData[scope.$index]['id']) >= 0"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="level"
            label="奖品等级"
            :render-header="renderHeader"
            min-width="140"
            align="center">
            <template slot="header">
              <span>
                <span style="color: #f5222d">&nbsp;*&nbsp;</span>
                <span>奖品等级</span>
              </span>
            </template>
            <template slot-scope="scope">
              <el-input type="number" size="small" v-model="prizeData[scope.$index]['level']" min="1" max="7" :maxlength="1" @blur="validNumber(scope.$index, 'level')"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="awardNum"
            label="奖品总数量"
            :render-header="renderHeader"
            min-width="150"
            align="center">
            <template slot-scope="scope">
              <el-input type="number" size="small" v-model="prizeData[scope.$index]['awardNum']" @blur="validNumber(scope.$index, 'awardNum')"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="percent"
            label="中奖概率"
            :render-header="renderHeader"
            min-width="140"
            align="center">
            <template slot-scope="scope">
              <el-input type="number" size="small" v-model="prizeData[scope.$index]['percent']" min="0" max="100" @blur="validNumber(scope.$index, 'percent')">
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="remain"
            label="剩余可支配奖品数量"
            :render-header="renderHeader"
            min-width="150"
            align="center">
            <template slot-scope="scope">
              <span>{{ prizeData[scope.$index]['remain'] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="userNames"
            :render-header="renderPrizeUser"
            label="中奖人"
            min-width="200"
            align="center">
            <template slot-scope="scope">
              <el-select
                v-model="prizeData[scope.$index]['userNames']"
                multiple
                filterable
                default-first-option
                allow-create
                :multiple-limit="prizeData[scope.$index]['awardNum'] ? parseInt(prizeData[scope.$index]['awardNum']) : 0"
                @remove-tag="handleRemoveTag(scope.$index, $event)"
                @change="changeWinners(scope.$index, $event)"
                >
                <el-option
                  v-for="item in allUsers"
                  :key="item.value"
                  :label="item.label"
                  :disabled="winNames.includes(item.label)"
                  :value="item.label">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
        <br />
      </div>
      <el-form ref="form" :model="form" label-width="140px">
        <el-form-item label="中奖提示信息">
          <el-input type="textarea" v-model="form.message" :autosize="{ minRows: 4}" clearable maxlength="200" show-word-limit placeholder="用户中奖后展示的补充提示信息。如输入“如有疑问，请联系133XXXXXXXX”，则中奖后提示用户“恭喜获得X等奖XXX。如有疑问，请联系133XXXXXXXX”"></el-input>
        </el-form-item>
        <el-form-item label="收集中奖人收货信息">
          <el-radio-group v-model="form.isFillPunter">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <div style="width: 100%; text-align: right">
            <el-button @click="lotteryDialogCancel">取消</el-button>
            <el-button type="primary" @click="saveLottery" style="margin-left: 10px">保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="设置问卷有效期" :visible.sync="expirationDialogShow" :close-on-click-modal="true" class="dialog">
      <el-form ref="willSetExpiration" :model="setOptions" label-width="160px">
        <el-form-item label="问卷到期时间" style="width: 100%" prop="expirationTime"
          :rules="[{ required: true, message: '请选择问卷到期时间' }]">
          <el-date-picker v-model="setOptions.expirationTime" type="datetime" placeholder="选择日期时间"
            :disabled="setOptions.noExpirationTime" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="问卷永久有效" style="width: 100%">
          <el-switch @change="switchActive" v-model="setOptions.noExpirationTime"> </el-switch>
        </el-form-item>
        <el-form-item style="width: 100%; text-align: right">
          <el-button @click="expirationDialogShow = false">取消</el-button>
          <el-button type="primary" style="margin-left: 10px" @click="setExpiration('willSetExpiration')">确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getToken
  } from '@/api/user.js'
  import {
    // 市场活动相关
    getMarketTokenApi,
    getActivityEndtime,
    // 问卷相关
    updateCreator,
    getPaperList,
    createPaper,
    updatePaper,
    publishPaper,
    pausePaper,
    deletePaper,
    saveExpirationTime,
    existPaper,
    // 问题相关
    getQuestionsByPaperId,
    // 抽奖相关
    getLottery,
    getLotteryId,
    createLottery,
    updateLottery,
    saveOrUpdateAward,
    getPrize,
    deleteAwards,
    stopOrStartLottery,
    getUserAccountAndName,
    // 结果统计相关
    getDataByPaperId,
    getLotteryDataByPaperId,
  } from "@/api/api";

  import fileDownload from "js-file-download";
  import Design from "./Design.vue";
  import QRCode from "qrcode";
  import axios from "axios";
  import moment from 'moment'
  export default {
    components: {
      Design,
      QRCode,
    },

    created() {},

    data() {
      return {
        setOptions: {
          expirationTime: "", // 问卷有效时间
          noExpirationTime: false, // 问卷是否永久有效
        },
        awardResult: [], // 抽奖信息
        people: {
          // 回答人数
          total: "0",
          inner: "0",
          outer: "0",
        },
        company: "", //回答者的公司
        expirationTime: "", //问卷有效时间
        lotteryState: "0", //抽奖活动状态
        lotteryId: "", // 抽奖活动ID
        paperIdFromCompaignIdAndType: '', // campaignId和type对应的问卷id
        prizeData: [],
        defaultActive: -1, // 当前激活菜单，默认第一条，若从其他平台跳转，通过id定位到index，高亮显示
        activeName: "paperDesign", // 标签页当前选择项
        paperList: [], // 问卷列表
        loading: false, // 是否显示加载中
        tableLoading: false, // 内部弹窗的加载中显示
        dialogLoading: false, // 对话框的加载中显示
        dialogShow: false, // 添加问卷弹窗是否显示
        shareDialogShow: false, // 分享问卷弹窗是否显示
        lotteryDialogShow: false, // 抽奖设置弹窗是否显示
        expirationDialogShow: false, // 有效时间设置弹窗显示
        hadPosition: false, // 是否触发问卷定位，触发问卷定位说明活动id存在对应问卷，禁止用户再次新增问卷
        isAddPaper: true,
        allUsers: [],
        willAddPaper: {
          id: 0,
          title: "",
          flag: 0,
          desc: "感谢您能抽时间参与本次问卷，您的意见和建议就是我们前行的动力！",
        },
        shareInfo: {
          url: "",
        },
        form: {
          message: '',
          isFillPunter: '1',
        },
        winNames: [], // 已经中奖的人的姓名
        sentAwardIds: [], // 已经抽出去的奖品id
      };
    },

    mounted() {
      // 登录校验
      this.logincheck();

    },

    computed: {
      // 当前选中的问卷信息
      nowSelect() {
        this.loading = true;

        // 获取问卷信息
        let now = this.paperList[this.defaultActive];
        if (this.paperList.length === 0 || this.defaultActive === -1) {
          this.loading = false;
          return {
            id: null,
            title: null,
            desc: null,
            status: null,
          };
        }
        // 获取问卷抽奖状态
        this.getLotteryStatus(now);
        // 获取问卷回答相关信息
        this.getPaperResultInfo(now);
        // 获取抽奖结果相关信息
        this.getLotteryResultInfo(now);

        // 延迟300毫秒显示一下加载的动画效果
        setTimeout(() => {
          this.loading = false;
        }, 300);

        return {
          id: now.id,
          title: now.paperTitle,
          desc: now.paperDesc,
          status: now.paperState,
          campaignId: now.activityId,
          type: now.paperType,
          expirationTime: now.expirationTime,
        };
      },
    },
    methods: {
      // 初始化
      init() {
        // 独立系统默认选取第一个问卷
        if (!sessionStorage.getItem('campaignId') && this.defaultActive === -1) {
          this.defaultActive = 0;
        }

        // 获取所有的用户，用于抽奖设置中奖人使用
        getUserAccountAndName().then((data) => {
          if (data.code === 200) {
            this.allUsers = data.data.result;
          } else if (data.code === 401) {
            this.toLogin();
          } else {
            this.$message({
              type: "error",
              message: data.message,
            });
          }
        });
      },
      // 抽奖设置弹框点击取消
      lotteryDialogCancel(){
        // 重置获奖提示和是否需要填写收货信息
        this.lotteryDialogShow = false;
        this.form = { message: '', isFillPunter: '1' };
      },

      // 已经有用户抽中的奖品不允许删除
      checkboxInit(row, index) {
        if (this.sentAwardIds.indexOf(row.id) >= 0) {
          return 0; //不可勾选
        } else {
          return 1; //可勾选
        }
      },
      // 处理移除多选的内定中奖人
      handleRemoveTag(row, value) {
        const index = this.winNames.indexOf(value)
        if (index !== -1) {
          this.prizeData[row]['userNames'].splice(index, 0, value)
          this.$message({
            type: 'info',
            message: '已经中奖的用户不支持删除',
          })
        }
      },
      // 校验
      checkAwards() {
        let res = true;
        let reg = new RegExp('^[ ]*$'); // 全为空格=>true，否则=>false
        const fields = ['name', 'level', 'awardNum', 'percent'];
        for (let i = 0; i < this.prizeData.length; i++) {
          for (const key in this.prizeData[i]) {
            if (fields.includes(key) && (!this.prizeData[i][key] || reg.test(this.prizeData[i][key]))) {
              res = false;
            } else if (fields.includes(key) && this.prizeData[i][key]) {
              res = this.validNumber(i, key);
            }
            if (!res) {
              break;
            }
          }
        }
        return res;
      },
      isRepeat(arr) { 
        let hash = {}; 
        for (let i in arr) { 
          if (hash[arr[i]]) 
            return true; 
          hash[arr[i]] = true; 
        } 
        return false; 
      },
      // 保存抽奖活动信息
      async saveLottery() {
        // 校验
        let res = this.checkAwards();
        if (res) {
          // 一个人只能设置一个内定奖品
          let temp = this.prizeData.map(item => {
            return item.userNames
          });
          let blackBox = [];
          temp.forEach(e => {
            blackBox.push(...e)
          });
          if (this.isRepeat(blackBox)) {
            this.$message({
              type: 'warning',
              message: '每个用户最多只能内定一种奖品',
            });
          } else if (!this.checkOddsSum()) {
            this.$message({
              type: 'error',
              message: '所有非100%中奖的奖品，中奖概率之和不能大于100%',
            });
          } else {
            this.dialogLoading = true;
            const parameter = {
              id: this.lotteryId,
              message: this.form.message,
              isFillPunter: this.form.isFillPunter,
            }
            const { code, message } = await updateLottery(parameter);
            if (code === 200) {
              // 保存奖品
              this.saveAwards();
            } else if (code === 401) {
              this.toLogin();
            } else {
              this.$message({
                type: 'error',
                message: message,
              });
            }
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请检查奖品信息是否填写正确',
          });
        }
      },
      // 检查中奖概率之和：除开百分百中奖率的奖品，剩余奖品的所有中奖概率和<=1
      checkOddsSum() {
        let sum = 0;
        this.prizeData.forEach(item => {
          if (item.percent && item.percent !== '100') {
            sum += item.percent * 1;
          }
        });
        return sum > 100 ? false : true;
      },
      // 保存奖品信息
      async saveAwards() {
        const parameter = this.prizeData;
        const { code, message } = await saveOrUpdateAward(parameter);
        if (code === 200) {
          // 保存成功
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.dialogLoading = false;
          this.lotteryDialogShow = false;
          // 重置获奖提示和是否需要填写收货信息
          this.form = { message: '', isFillPunter: '1', };
        } else if (code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: 'error',
            message: message,
          });
        }
      },
      // 设置中奖人
      changeWinners(row, val) {
        this.prizeData[row].userNames = val;
        // 中奖人改变，重新计算剩余数量
        // this.prizeData[row].remain = this.prizeData[row].awardNum - val.length;
        // 限制每个人只能被内定1次
      },
      renderHeader(h, { column, $index }) {
        let content = null;
        switch (column.property) {
          case 'name':
            content = '已经有用户抽中的奖品不允许再修改名称，且不允许删除。';
            break;
          case 'level':
            content = '“1”表示“一等奖”，“2”表示二等奖，以此类推。';
            break;
          case 'awardNum':
            content = '当前奖品的总数量，包含已抽出去的和未抽出去的。';
            break;
          case 'percent':
            content = '每个用户抽奖的概率都一样，单位为百分之一。如输入5，则每个用户抽中该奖品的概率均为百分之五。内定为最高优先级。';
            break;
          case 'remain':
            content = '剩余可支配奖品数量 = 奖品总数量 - 非内定的已抽出的数量 - 内定中奖人数';
            break;
          default:
            content = '';
            break;
        }
        return h(
          'div',
          [ 
            h('span', {
              style: 'color: red'
            },
            [h('span', ' * ')]),
            h('span', column.label),
            h(
              'el-tooltip',
              {
                props: {
                  content, // 鼠标悬停时要展示的文字提示
                  placement: 'top'
                }
              },
              [h('span', { class: { 'el-icon-question': true }})], // 图标
            )
          ],
        );
      },
      renderPrizeUser(h, { column, $index }) {
        return h(
          'div',
          [ 
            h('span', column.label),
            h(
              'el-tooltip',
              {
                props: {
                  content: `指定中奖人，若该用户参与此次抽奖活动，则一定会抽中该奖品。如奖品总数为3，指定了1名中奖人，则其余用户只能在剩下的2份奖品中抽取。注：不能排除同名用户将奖品中走的情况，已经中奖的用户不能再取消内定。`, 
                  placement: 'top'
                }
              },
              [h('span', { class: { 'el-icon-question': true }})], // 图标
            )
          ],
        );
      },
      // 验证是否为正整数
      validNumber(row, column) {
        let res = true;
        let reg = new RegExp('^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$'); // 正整数
        switch(column) {
          case 'level':
            // 1-8正整数
            reg = new RegExp('^[1-7]$');
            if (!reg.test(this.prizeData[row][column])) {
              this.$message({
                type: 'info',
                message: '奖品等级为1-7的整数',
              });
              this.prizeData[row][column] = '';
              res = false;
            } else {
              res = true;
            }
            break;
          case 'awardNum':
            // 正整数
            reg = new RegExp('^[0-9]*[1-9][0-9]*$');
            if (!reg.test(this.prizeData[row][column])) {
              this.$message({
                type: 'info',
                message: '奖品总数量为正整数',
              });
              this.prizeData[row][column] = '';
              res = false;
            } else {
              if (parseInt(this.prizeData[row][column]) < this.prizeData[row]['userNames'].length) {
                this.$message({
                  type: 'info',
                  message: '奖品总数量不能少于内定中奖人数',
                });
                this.prizeData[row][column] = this.prizeData[row]['userNames'].length;
                res = false;
              } else {
                if (!this.prizeData[row].id) {
                  this.prizeData[row].remain = this.prizeData[row][column];
                }
                res = true;
              }
            }
            break;
          case 'percent':
            // 有1~2位小数的正实数
            reg = new RegExp('^[0-9]+(\.[0-9]{1,2})?$');
            if (!reg.test(this.prizeData[row][column])) {
              this.$message({
                type: 'info',
                message: '中奖概率为正数(最多2位小数)',
              });
              this.prizeData[row][column] = '';
              res = false;
            } else if(this.prizeData[row][column] > 100) {
              this.prizeData[row][column] = '100';
            } else {
              res = true;
            }
            break;
          default:
            break;
        }
        return res;
      },
      // 是否禁用创建问卷按钮
      ifDisabledAddPaperButton() {
        if (this.loading || this.hadPosition) {
          // 加载中的时候 || 触发定位已经有对应问卷的时候
          return true;
        } else {
          return false;
        }
      },
      // 获取抽奖结果相关信息
      async getLotteryResultInfo(now) {
        // 获取抽奖结果相关信息
        let res = await getLotteryDataByPaperId({
          paperId: now.id,
        })
        if (res.code === 200) {
          this.awardResult = res.data.result;
          this.winNames = res.data.result.map(item => {
            return item.userName
          })
          this.sentAwardIds = Array.from(new Set(res.data.result.map(item => {
            return item.awardId
          })));
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      },

      // 获取问卷回答相关信息
      async getPaperResultInfo(now) {
        let res = await getDataByPaperId({
          paperId: now.id,
        })
        this.people.total = "0";
        this.people.inner = "0";
        this.people.outer = "0";
        this.expirationTime = "永久有效";
        this.company = "暂未有用户填写";

        if (res.code === 200) {
          // 判断是否存在统计信息，peopleNum、company、validTime会同时出现判断一个即可
          if (res.data.peopleNum !== undefined) {
            // 回答人数
            if (res.data.peopleNum.userTotal) {
              this.people.total = res.data.peopleNum.userTotal;
            }
            if (res.data.peopleNum.internalTotal) {
              this.people.inner = res.data.peopleNum.internalTotal;
            }
            if (res.data.peopleNum.externalTotal) {
              this.people.outer = res.data.peopleNum.externalTotal;
            }
            // 回答者的公司
            if (res.data.company) {
              this.company = res.data.company.join("、");
            }
            // 问卷有效时间
            if (res.data.validTime === "null天null时null分") {
              this.expirationTime = "永久有效";
            } else {
              this.expirationTime = res.data.validTime;
            }
          }
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      },

      // 获取抽奖活动状态
      async getLotteryStatus(now) {
        let res = await getLottery({
          paperId: now.id,
        })
        if (res.code === 200 && res.data.lottery) {
          this.lotteryState = res.data.lottery.state;
          this.form = {
            message: res.data.lottery.message ? res.data.lottery.message : '',
            isFillPunter: res.data.lottery.isFillPunter ? res.data.lottery.isFillPunter : '1',
          }
        } else if (res.code === 200 && !res.data.lottery) {
          // 不存在抽奖活动的情况
          this.lotteryState = '0';
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      },

      // 问卷永久有效滑块激活消除已选择的有效时间
      switchActive(value) {
        if (value) {
          this.setOptions.expirationTime = '';
          this.$nextTick(() => {
            this.$refs.willSetExpiration.clearValidate()
          })
        }
      },

      // 动态设置添加问卷弹窗的title
      getTypeTitle() {
        let type = sessionStorage.getItem('type');
        if (type) {
          if (type === '0') {
            return '添加预报名问卷';
          } else if (type === '1') {
            return '添加反馈问卷';
          }
        }
        return '添加问卷';
      },

      // 获取问卷列表
      async getPaperList() {
        this.loading = true;

        const {
          code,
          data,
          message
        } = await getPaperList({
          operatorInfo: JSON.parse(sessionStorage.getItem("user")).ecoAccountId,
          // operatorInfo: JSON.parse(sessionStorage.getItem("user")).email,
        });
        if (code === 200) {
          if (data.paperList.length === 0) {
            this.paperList = [];
            this.loading = false;
            // 获取当前选中问卷题目
            this.lookDetail();
          }
          this.paperList = data.paperList;
          this.loading = false;
          // 获取当前选中问卷题目
          this.lookDetail();
          return data.paperList;
        } else if (code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: message,
          });
        }
      },

      // 通过活动id和问卷类型定位问卷
      async positionPaper(paperId) {
        let paperList = [];

        const campaignId = sessionStorage.getItem("campaignId");
        const type = sessionStorage.getItem("type");

        // 这一步获取问卷列表
        paperList = this.paperList;

        // 如果直接知道paperId就不用通过campaignId和type去获取paperId了
        if (!paperId) {
          // 这一步通过campaignId判断问卷是否存在
          let result = await existPaper({
            campaignId: campaignId,
            type: type
          });

          // 这一步是获取问卷id
          if (result.code === 200) {
            if (!result.data.paperId) {
              // 走到这里说明没有对应的问卷
              paperId = '-1';
              // 打开新建问卷弹框
              this.isAddPaper = true;
              this.dialogShow = true;
              return;
            } else {
              paperId = result.data.paperId;
            }
          } else if (result.code === 401) {
            this.toLogin();
          } else {
            this.$message({
              type: 'error',
              message: result.message,
            })
          }
        }

        // 这一步是比对问卷id获取问卷的在list的位置（下标）, 赋值给defaultActive
        let positionActivity = -1; // 先给个默认值-1
        paperList.forEach((item, index) => {
          if (item.id === paperId) {
            positionActivity = index;
          }
        })

        if (positionActivity !== -1) {
          // 当前问卷列表有campaignId和type对应的问卷
          this.defaultActive = positionActivity;
          if (sessionStorage.getItem('campaignId')) {
            this.hadPosition = true;
          }
          this.lookDetail();
        } else {
          this.hadPosition = false;
        }
      },

      // 导出问卷回答数据
      async exportAnswer() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        axios.get("/api/paper/exportAnswer", {
            params: {
              paperId: this.nowSelect.id,
            },
            responseType: "blob",
            headers: {
              'token': sessionStorage.getItem('LocalToken')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              loading.close();
              fileDownload(
                res.data,
                decodeURIComponent(
                  res.headers["content-disposition"].split("=")[1]
                )
              );
            } else if (res.status === 401) {
              this.toLogin();
            } else {
              loading.close();
              this.$message({
                type: "error",
                message: res.data.message,
              });
            }
          });
      },
      // 暂停/开始抽奖活动
      async lotteryStop() {
        if (this.nowSelect.status === "0") {
          this.$message({
            type: "info",
            message: "请暂停问卷后进行编辑",
          });
          return;
        }

        this.loading = true;
        // 判断抽奖活动有没有奖品，没有奖品禁止开始抽奖活动，并进行提示
        let res = await getLotteryId({
          paperId: this.nowSelect.id,
        })
        if (res.code === 200) {
          this.lotteryId = res.data.lotteryId;
          let res2 = await getPrize({
            LotteryId: this.lotteryId,
          })
          if (res2.code === 200) {
            this.prizeData = res2.data.result;
            
            this.prizeData.forEach((item) => {
              item.sentNum = item.awardNum - item.remain;
            });
          } else if (res2.code === 401) {
            this.toLogin();
          } else {
            this.$message({
              type: "error",
              message: res2.message,
            });
          }
          if (this.prizeData.length === 0) {
            this.$message({
              type: "info",
              message: "请设置抽奖环节的奖品",
            });
            this.loading = false;
            return;
          }
        } else if (res.code === 401) {
          this.toLogin();
        }


        res = await stopOrStartLottery({
          paperId: this.nowSelect.id,
        })
        if (res.code === 200) {
          this.lotteryState = res.data.lottery.state;
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "info",
            message: '请设置抽奖环节',
          });
        }
        this.loading = false;
      },
      handleSelection(val, row) {
        this.multipleSelection = val;
      },
      // 删除奖品
      async removeCheckboxRow() {
        let saved = this.prizeData.filter(item => {
          if (this.$refs.multipleTable.selection.indexOf(item) > -1 && item.id) {
            return item;
          }
        }).map(e => e.id)
        this.$confirm(
          "确定删除选中的奖品?",
          "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(async () => {
          // 删除已保存的
          if (saved.length > 0) {
            this.delSavedAwards(saved);
          } else {
            this.removeSelection();
          }
        }).catch(res => {
          console.log(res);
        });
      },

      // 移除选中的奖品
      removeSelection() {
        this.prizeData = this.prizeData.filter(item => {
          if (this.$refs.multipleTable.selection.indexOf(item) === -1) {
            return item;
          }
        })
      },
      // 删除奖品
      async delSavedAwards(saved) {
        this.dialogLoading = true;
        const { code, message } = await deleteAwards(saved)
        if (code === 200) {
          this.dialogLoading = false;
          this.removeSelection();
        } else if (code === 401) {
          this.dialogLoading = false;
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: message,
          });
        }
      },

      // 抽奖活动对话框取消
      cancelEvent() {
        this.lotteryDialogShow = false;
        this.$refs.xTable.revertData();
      },

      // 打开抽奖活动的同时获取抽奖活动ID
      async openSetLotteryDialog() {
        if (this.nowSelect.status === "0") {
          this.$message({
            type: "info",
            message: "请暂停问卷后进行编辑",
          });
          return;
        }

        this.dialogLoading = true;
        this.tableLoading = true;
        this.lotteryDialogShow = true;
        // 判断抽奖活动是否存在
        let result = await await getLottery({
          paperId: this.nowSelect.id,
        })

        if (result.data.lottery) {
          // 抽奖活动存在
          this.lotteryId = result.data.lottery.id;
          this.lotteryState = result.data.lottery.state;
          this.form = {
            message: result.data.lottery.message ? result.data.lottery.message : '',
            isFillPunter: result.data.lottery.isFillPunter ? result.data.lottery.isFillPunter : '1',
          }
        } else {
          // 抽奖活动不存在则新增
          let res = await createLottery({ paperId: this.nowSelect.id });
          if (res.code === 200) {
            this.lotteryId = res.data.lotteryId;
          } else if (data.code === 401) {
            this.toLogin();
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        }

        let res1 = await getPrize({LotteryId: this.lotteryId})
        if (res1.code === 200) {
          this.prizeData = res1.data.result;
          this.prizeData.forEach((item) => {
            item.sentNum = item.awardNum - item.remain;
          });
        } else if (data.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res1.message,
          });
        }
        setTimeout(() => {
          this.tableLoading = false;
          this.dialogLoading = false;
        }, 500);
      },

      // 新增奖品信息
      addPrize() {
        if (this.prizeData.length < 7) {
          this.prizeData.push({
            lotteryId: this.lotteryId,
            name: '',
            level: '',
            awardNum: '',
            percent: '',
            remain: '',
            userNames: '',
          })
        } else {
          this.$message({
            type: 'info',
            message: '最多设置7条奖品记录',
          });
        }
      },

      // 检查新增奖品是否为空
      checkItemIsNull(awards) {
        let result = false;
        try {
          awards.forEach((award) => {
            if (
              // award.awardType === "" ||
              award.name === "" ||
              award.level === "" ||
              award.awardNum === "" ||
              award.percent === ""
            ) {
              result = true;
              // 抛一个异常终止forEach循环
              throw new Error("forEachEnd");
            }
          });
        } catch (error) {}
        return result;
      },

      // 检查中奖人数是否大于奖品数
      checkPeopleNumMoreThanAwardNum(awards) {
        let result = false;
        try {
          awards.forEach((award) => {
            if (award.userNames.length > parseInt(award.awardNum)) {
              result = true;
              this.$message({
                type: "warning",
                message: award.name + " 的中奖人数大于奖品数",
              });
              // 抛一个异常终止forEach循环
              throw new Error("forEachEnd");
            }
          });
        } catch (error) {}
        return result;
      },

      // 保存或更新奖品信息
      async saveEvent() {
        this.dialogLoading = true;
        // 表格中所有的数据
        let allRecords = this.$refs.xTable.getTableData().fullData;

        // 判断是否存在异常数据
        if (this.checkItemIsNull(allRecords)) {
          this.$message({
            type: "info",
            message: "仍有信息未填写，请检查奖品名称、奖品等级、奖品总数量、抽奖概率是否全已填写",
          });
          this.dialogLoading = false;
          return;
        }

        // 判断中奖人数是否大于奖品数
        if (this.checkPeopleNumMoreThanAwardNum(allRecords)) {
          this.dialogLoading = false;
          return;
        }

        // 判断是否有奖品，没有奖品禁止保存
        if (allRecords.length === 0) {
          this.$message({
            type: "info",
            message: "请添加奖品",
          });
          this.dialogLoading = false;
          return;
        }

        // 因为后台userId接收字符串所以处理userNames的格式为字符串
        let prizeData = JSON.parse(JSON.stringify(allRecords));
        prizeData.forEach((item) => {
          item.lotteryId = this.lotteryId;
        });
        // 保存或更新奖品信息
        let res = await saveOrUpdateAward(prizeData)
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.lotteryDialogShow = false;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
        this.dialogLoading = false;
      },

      // 检查登录是否过期
      async logincheck() {
        if (sessionStorage.getItem('token')) {
          // 修改问卷创建人
          const campaignId = sessionStorage.getItem('campaignId');
          const type = sessionStorage.getItem('type');
          if (campaignId && type) {
            // 有活动id和type判断有没有问卷
            let result = await existPaper({
              campaignId: campaignId,
              type: type
            });
            if (result.code === 200 && result.data.paperId) {
              const user = JSON.parse(sessionStorage.getItem('user'));
              updateCreator({
                paperId: result.data.paperId,
                newCreator: user.ecoAccountId
              }).then(async () => {
                this.init();
                await this.getPaperList();
                if (sessionStorage.getItem('campaignId') && sessionStorage.getItem('type')) {
                  this.positionPaper();
                }
              });
            } else if (result.code === 200 && !result.data.paperId) {
              this.init();
              await this.getPaperList();
              // 没有问卷，则创建问卷
              this.addPaperButtonClick();
            } else {
              this.$message({
                type: "error",
                message: result.message,
              });
            }
          } else {
            this.init();
            await this.getPaperList();
          }

        } else {
          this.$router.push({
            path: '/index'
          });
        }
      },

      // 设置过期时间
      async setExpiration(formName) {
        // 这个paperId用于重新定位
        const paperId = this.nowSelect.id;

        if (this.setOptions.noExpirationTime) {
          // 问卷永久有效
          let res = await saveExpirationTime({
            paperId: this.nowSelect.id,
            expirationTime: 'forever',
          })
          if (res.code !== 200) {
            this.$message({
              type: "error",
              message: res.message,
            });
            return;
          }

          res = await publishPaper({
            paperId: this.nowSelect.id,
          })
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "问卷发布成功",
            });
            this.getPaperList().then(()=>{
              this.positionPaper(paperId);
            });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }

          this.expirationDialogShow = false;
        } else {
          // 用户设置了过期时间
          this.$refs[formName].validate(async (valid) => {
            if (valid) {
              let res = await saveExpirationTime({
                paperId: this.nowSelect.id,
                expirationTime: this.setOptions.expirationTime,
              })
              if (res.code !== 200) {
                this.$message({
                  type: "error",
                  message: res.message,
                });
                return;
              }

              let expirationTime = new Date(this.setOptions.expirationTime);
              let nowTime = new Date();
              // 设置的时间已经过期，后端会将问卷设置为过期状态，前端只需刷新列表及关闭弹框
              if (expirationTime < nowTime) {
                // 刷新问卷列表
                this.getPaperList().then(()=>{
                  this.positionPaper(paperId);
                });
                // 关闭弹框
                this.expirationDialogShow = false;
                return;
              }

              res = await publishPaper({
                paperId: this.nowSelect.id,
              })
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "问卷发布成功",
                });
                this.getPaperList().then(()=>{
                  this.positionPaper(paperId);
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }

              // 关闭弹框
              this.expirationDialogShow = false;
            } else {
              this.$message({
                type: "info",
                message: "问卷到期时间不能为空",
              });
              return false;
            }
          });
        }
      },

      // 发布问卷/暂停问卷
      async changPaperStatus() {
        // 这个paperId用于重新定位
        const paperId = this.nowSelect.id;
        // 问卷至少有一道题才允许发布
        if(this.nowSelect.status !== "0"){
          let res = await getQuestionsByPaperId({
            paperId: this.nowSelect.id,
          })
          if (res.code === 200) {
            let detail = res.data.paper.questions;
            if (!detail || detail.length === 0) {
            this.$message({
              type: 'info',
              message: '请添加题目',
            });
              return;
            }
          }
        }

        // 只有发布问卷的时候才打开设置过期时间的弹框
        if (this.nowSelect.status !== "0") {
          // 有活动id才会去取活动的到期时间
          let campaignId = sessionStorage.getItem('campaignId');
          if (campaignId) {
            let res = {};
            // 获取市场活动的token
            res = await getMarketTokenApi();
            const marketToken = res.token;
            // 获取活动结束时间戳
            res = await getActivityEndtime({campaignId}, marketToken);
            if (res.status && res.status === 1) {
              this.setOptions.expirationTime = moment(parseInt(res.endTime)).format('YYYY-MM-DD HH:mm:ss');
            }
          }
          this.expirationDialogShow = true;
        } else {
          let res = await pausePaper({
            paperId: this.nowSelect.id,
          })
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "问卷暂停成功",
            });
            this.getPaperList().then(()=>{
              // 重新定位到修改的问卷
              this.positionPaper(paperId);
            });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        }
      },

      // 分享问卷
      sharePaper() {
        // 市场活动的问卷不可分享
        if (this.nowSelect.type === "0" || this.nowSelect.type === "1") {
          this.$message({
            type: "info",
            message: "市场活动的问卷不可通过此方式分享",
          });
          return;
        }

        if (this.nowSelect.status === "1") {
          // 问卷未发布
          this.$message({
            type: "info",
            message: "请先发布问卷再进行分享",
          });
          return;
        }
        // 拼接问卷链接
        if (this.nowSelect.campaignId && this.nowSelect.type) {
          this.shareInfo.url = location.origin + "/answer?campaignId=" + this.nowSelect.campaignId + "&type=" + this
            .nowSelect.type;
        } else {
          this.shareInfo.url = location.origin + "/index?toPath=%2Fanswer&paperId=" + this.nowSelect.id;
        }

        this.shareDialogShow = true;
      },

      // 生成二维码
      makeQrcode() {
        let canvas = document.getElementById("canvas");
        QRCode.toCanvas(canvas, this.shareInfo.url);
      },

      // 复制分享链接成功
      copyUrlSuccess(e) {
        this.$message({
          type: "success",
          message: "已复制链接到剪切板",
        });
      },

      // 复制分享链接失败
      copyUrlError(e) {
        this.$message({
          type: "error",
          message: "复制失败",
        });
      },

      // 打开分享链接
      openShareUrl() {
        window.open(this.shareInfo.url);
      },

      // 预览问卷
      previewPaper() {
        let url = location.origin + "/preview/" + this.nowSelect.id; // 问卷链接
        window.open(url);
      },

      // 编辑问卷
      editPaper() {
        // 已发布的问卷不能进行编辑，需修改状态为未发布后再进行编辑
        if (this.nowSelect.status === "0") {
          this.$message({
            type: "info",
            message: "请暂停问卷后进行编辑",
          });
          return;
        }
        this.isAddPaper = false;
        this.dialogShow = true;
        this.willAddPaper = this.nowSelect;
      },

      // 添加问卷按钮点击
      addPaperButtonClick() {
        this.isAddPaper = true;
        this.dialogShow = true;
        this.willAddPaper = {
          id: 0,
          title: "",
          flag: 0,
          desc: "感谢您能抽时间参与本次问卷，您的意见和建议就是我们前行的动力！",
        };
      },

      // 添加问卷
      addPaper(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            // 提交问卷
            this.dialogLoading = true;
            if (this.isAddPaper) {
              let res = await createPaper({
                paperDesc: this.willAddPaper.desc, // 问卷描述
                paperState: "1", // 问卷状态 0已发布，1未发布
                paperTitle: this.willAddPaper.title, // 问卷标题
                creator: JSON.parse(sessionStorage.getItem("user")).ecoAccountId, // 创建者
                paperType: sessionStorage.getItem("type") ? sessionStorage.getItem("type") : '2', // 问卷类型
                activityId: sessionStorage.getItem("campaignId"), // 活动id
                platform: sessionStorage.getItem("appid"), // 平台id/平台信息
              })
              if (res.code === 200) {
                // 提示保存成功
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                // 重新获取问卷列表
                this.getPaperList().then(() => {
                  // 定位到新添加的问卷
                  this.positionPaper(res.data.paperId);
                  if (sessionStorage.getItem('campaignId')) {
                    this.paperIdFromCompaignIdAndType = res.data.paperId;
                  }
                });
                // 显示新添加的问卷
                this.lookDetail();
              } else {
                // 提示保存失败
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }
              this.dialogLoading = false;
              this.dialogShow = false;
              this.willAddPaper.title = "";
            } else {
              let res = await updatePaper({
                paperId: this.willAddPaper.id, // 问卷id
                describe: this.willAddPaper.desc, // 问卷描述
                title: this.willAddPaper.title, // 问卷标题
              })
              if (res.code === 200) {
                // 提示保存成功
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                // 重新获取问卷列表
                this.getPaperList().then(()=>{
                  // 重新定位到修改的问卷
                  this.positionPaper(this.willAddPaper.id);
                });
              } else {
                // 提示保存失败
                this.$message({
                  type: "error",
                  message: res.message,
                });
              }
              this.dialogLoading = false;
              this.dialogShow = false;
            }
          } else {
            this.$message({
              type: "info",
              message: "问卷标题不能为空",
            });
            return false;
          }
        });
      },

      // 删除问卷
      deletePaper() {
        this.$confirm(
          "确定删除【" + this.nowSelect.title + "】？删除后不可恢复！",
          "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(async () => {
          this.loading = true;

          let res = await deletePaper({
            paperId: this.nowSelect.id,
            operatorInfo: JSON.parse(sessionStorage.getItem("user")).ecoAccountId,
            // operatorInfo: JSON.parse(sessionStorage.getItem("user")).email,
          })
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.loading = false;
            // 开启创建问卷的按钮
            if (sessionStorage.getItem('campaignId') && this.nowSelect.id === this.paperIdFromCompaignIdAndType) {
              this.hadPosition = false;
            }
            // 重新获取问卷列表
            this.getPaperList();
            // 定位到第一条
            this.defaultActive = 0;
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        }).catch(res => {
          console.log(res);
        });
      },

      // 问卷点击
      paperClick(id, index) {
        this.defaultActive = index;
        this.lookDetail();
      },

      // 查看问卷详情
      lookDetail() {
        // 初始化问卷的所有题
        this.$refs.design.init(
          this.nowSelect.id,
          this.nowSelect.status,
          this.nowSelect.title,
          this.nowSelect.desc
        );
      },

      // token失效，需要重新获取token
      async toLogin() {
        let { code, data, message } = await getToken();
        if (code === 200) {
          sessionStorage.setItem('LocalToken', data.token);
          location.reload();
        } else {
          this.$message({
            type: 'error',
            message: message
          })
        }
      },
      // methods底部
    },
  };
</script>
<style scoped>
  .awardResult {}

  .home {
    position: absolute;
    width: 100%;
    height: calc(100vh - 100px);
    text-align: left;
  }

  .home .badgeItem {
    margin-top: 40px;
  }

  .content {
    padding: 20px;
    padding-right: 50px;
    height: calc(100vh - 175px);
    text-align: center;
    overflow-x: hidden;
    background-image: url("../../static/images/bg.svg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .menu {
    background-color: white;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    left: 0;
  }

  .title {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
  }

  .home .opera {
    position: relative;
    background-color: #fafafa;
    text-align: center;
    height: 40px;
  }

  .home .rightButton {
    font-size: 16px;
  }

  .home .addPaperDiv {
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .is-active {
    outline: 0;
    background-color: #ebf3f2;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .text {
    font-size: 14px;
    font-weight: bold;
  }

  .item {
    /* padding: 18px 0; */
  }
</style>